<script>
import Component, { mixins } from 'vue-class-component';
import localizeFlatpickr from '../../localization/flatpickr';
import Flatpickr from 'flatpickr';
import ModelMixin from './ModelMixin';
import moment from 'moment';
import UiFormItem from './UIFormItem';
import UIIcon from './UIIcon';
import { ValidationProvider } from 'vee-validate';

@Component({
  name: 'UIDatepicker',
  props: {
    value: {
      type: String,
    },
    locale: {
      type: String,
      default: 'en-US',
    },
    utc: {
      default: false,
    },
    options: {
      default: () => ({}),
    },
    format: {
      type: String,
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    fieldNameOverride: {
      type: String,
    },
    darkMode: {
      type: Boolean,
      required: false,
    },
    vid: {
      type: String,
      default: undefined,
    },
    ...UiFormItem.options.props,
  },
  components: {
    UiFormItem,
    UIIcon,
    ValidationProvider,
  },
  watch: {
    internalValue (val) {
      if (this._flatpicker) this._flatpicker.setDate(this.internalDate);
    },
  },
})
export default class UIDatepicker extends mixins(ModelMixin) {
  get toMoment () { return this.utc ? moment.utc : moment; }
  get internalMoment () {
    return this.toMoment(this.internalValue, this.format).set('hour', 12);
  }

  get internalDate () {
    const date = new Date('YYYY-MM-DDTHH:mm:ss');
    date.setFullYear(this.internalMoment.format('YYYY'));
    date.setMonth(this.internalMoment.format('MM') - 1);
    date.setDate(this.internalMoment.format('DD'));
    date.setHours(this.internalMoment.format('HH'));
    date.setMinutes(this.internalMoment.format('mm'));
    date.setSeconds(this.internalMoment.format('ss'));
    // We do the above to remove tz offset
    return date;
  }

  onFlatpickrChange (date, formatted) {
    this.internalValue = this.toMoment(formatted, 'YYYY-MM-DD').format(this.format);
    this.$emit('change', this.internalValue);
    this.$emit('update', this.internalValue);
  }

  created () {
    const promise = localizeFlatpickr(this.locale);
    this.$nextTick(async () => {
      await promise;
      const input = this.$refs.input;
      const options = {
        altInput: true,
        altFormat: 'm/d/Y',
        onChange: this.onFlatpickrChange,
        defaultDate: this.value ? this.internalDate : undefined,
        onReady: (selectedDates, dateStr, instance) => {
          const calendar = instance.calendarContainer;
          if (this.darkMode) {
            calendar.classList.add('dark');
          }
        },
        ...this.options,
      };
      if (this.locale) options.locale = this.locale.split('-')[0];
      this._flatpicker = Flatpickr(input, options);
      const calendar = this._flatpicker.calendarContainer;
      if (this.darkMode && calendar) {
        calendar.classList.add('dark');
      }
      this.$watch('options', () => {
        if (!this._flatpicker) return;
        Object.keys(this.options).map((k) => {
          this._flatpicker.set(k, this.options[k]);
        });
      }, { deep: true });
    });
  }

  destroyed () {
    if (this._flatpicker) this._flatpicker.destroy();
  }
};
</script>

<template>
  <ValidationProvider :rules="rules" v-slot="{ errors }" :vid="vid" :name="!fieldNameOverride ? title : fieldNameOverride">
    <UiFormItem :title="title" :instructions="instructions" :required="required" :root="true">
      <div class="relative">
        <input class="ui-datepicker" ref="input" type="text" v-model="internalValue" :class="darkMode ? 'dark' : ''"/>
        <UIIcon class="chevron-down" name="chevron-down" ></UIIcon>
      </div>
    </UiFormItem>
    <span class="error">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<style lang="postcss">
@import "../../styles";

.chevron-down {
  position: absolute;
  right: var(--spacingSm);
  top: 1.4rem;
  width: 2rem;
  height: 2rem;
  fill: var(--colorManatee);
  pointer-events: none;
}

.ui-datepicker {
  @apply --text;
  min-width: 100%;
  width: 100%; /* default input size attributes makes the field overflow its parent sometimes */
}

.flatpickr-calendar {
  font-family: var(--fontSansSerif);
}

.flatpickr-current-month {
  font-size: 110% !important;
}
.error {
  @apply --f7;
  color: var(--colorErrorFlat);
}

.flatpickr-calendar.dark .flatpickr-months {
  background-color: var(--darkModeColorDarkBackground) !important;
  color: var(--darkModeColorText) !important;
  & .flatpickr-monthDropdown-months {
    background-color: var(--darkModeColorDarkBackground) !important;
    color: var(--darkModeColorText) !important;
  }
  & .numInputWrapper {
    background-color: var(--darkModeColorDarkBackground) !important;
    color: var(--darkModeColorText) !important;
  }
  & .numInputWrapper span.arrowUp:after {
    border-bottom-color: var(--darkModeColorText);
  }
  & .numInputWrapper span.arrowDown:after {
    border-top-color: var(--darkModeColorText);
  }
  & .flatpickr-next-month {
    fill: var(--darkModeColorText) !important;
  }
  & .flatpickr-prev-month {
    fill: var(--darkModeColorText) !important;
  }
}

.flatpickr-calendar.dark .flatpickr-innerContainer .flatpickr-rContainer {
  background-color: var(--darkModeColorDarkBackground) !important;
  color: var(--darkModeColorText) !important;
  & .flatpickr-weekdays .flatpickr-weekdaycontainer .flatpickr-weekday {
    background-color: var(--darkModeColorDarkBackground) !important;
    color: var(--darkModeColorText) !important;
  }
  & .flatpickr-days .dayContainer .flatpickr-day {
    background-color: var(--darkModeColorDarkBackground) !important;
    color: var(--darkModeColorText) !important;
  }
}
</style>
